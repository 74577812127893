import Steps from 'components/Commerce/Steps';
import { useEffect, useState } from 'react';
import PopUpCartItemAdded from 'components/Commerce/PopUpCartItemAdded';
import VariationStep from 'components/Commerce/Steps/VariationStep';
import CalendarStep from 'components/Commerce/Steps/CalendarStep';
import LoadingSpinner from 'components/Misc/LoadingSpinner';
import { getDefaultVariation } from 'lib/variations';
import BookForStep from 'components/Commerce/Steps/BookForStep';
import {
  BOOK_FOR,
  BookFor,
} from 'components/Commerce/Steps/BookForStep/BookForStep';
import ProductDetails from 'components/Commerce/Steps/ProductDetails';
import HeaderSubPage from 'components/HeaderSubPage';
import {
  convertAvailableCourseDates,
  getAvailableDatesForCourses,
} from 'lib/course-client';
import LoadingSpinnerWrapper from 'components/Misc/LoadingSpinnerWrapper';
import QuantityStep from 'components/Commerce/Steps/QuantityStep';
import TimeStepCourse from 'components/Commerce/Steps/TimeStepCourse';

interface CourseProductProps {
  product: any;
  productVariations: any;
}

export default function CourseProduct({
  product,
  productVariations,
  ...props
}: CourseProductProps) {
  const [selectedVariations, setSelectedVariations] = useState([
    getDefaultVariation(product, productVariations),
  ]);
  const [showPopUpCartItemAdded, setShowPopUpCartItemAdded] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [bookFor, setBookFor] = useState<BookFor>(BOOK_FOR.myself);
  const [loading, setLoading] = useState(false);
  const [availabilities, setAvailabilities] = useState(null);

  // We store the classes to get the class id after a timeslot has been selected.
  const getClassesForCourses = async () => {
    const dateResponse = await getAvailableDatesForCourses(selectedVariations);
    if (dateResponse?.classes) {
      setAvailabilities(dateResponse.classes);
    }
    return dateResponse;
  };

  useEffect(() => {
    setSelectedDate(null);
  }, [selectedVariations]);

  return (
    <article {...props} className="bg-tertiary">
      <PopUpCartItemAdded
        show={showPopUpCartItemAdded}
        setShow={setShowPopUpCartItemAdded}
      />
      {loading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner size={10} />
        </LoadingSpinnerWrapper>
      )}
      <div data-product-type="CourseProduct" className="relative">
        <div className="mx-auto w-full">
          <div className="relative sm:overflow-hidden">
            <HeaderSubPage
              title={product?.title}
              imageUrl={product?.field_image?.field_media_image?.uri?.url}
              videoUrl={null}
            />
            <Steps>
              <div>
                <ProductDetails product={product} />
                <VariationStep
                  productVariations={productVariations}
                  setSelectedVariations={setSelectedVariations}
                  selectedVariation={selectedVariations?.[0]}
                />
              </div>
              <div>
                <QuantityStep
                  productVariations={productVariations}
                  selectedVariations={selectedVariations}
                  setIsValidProductSelection={() => {}}
                  setSelectedVariations={setSelectedVariations}
                />
                <BookForStep bookFor={bookFor} setBookFor={setBookFor} />
                <CalendarStep
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedVariations={selectedVariations}
                  getAvailableDates={getClassesForCourses}
                  convertAvailableDates={convertAvailableCourseDates}
                />
              </div>
              {selectedDate && (
                <TimeStepCourse
                  selectedDate={selectedDate}
                  bookFor={bookFor}
                  setShowPopUpCartItemAdded={setShowPopUpCartItemAdded}
                  setLoading={setLoading}
                  selectedVariation={selectedVariations?.[0]}
                  availabilities={availabilities}
                ></TimeStepCourse>
              )}
            </Steps>
          </div>
        </div>
      </div>
    </article>
  );
}

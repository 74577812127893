interface LoadingSpinnerWrapperProps {
  children?: React.ReactNode;
  className?: string;
}

export default function LoadingSpinnerWrapper({
  children,
  className,
}: LoadingSpinnerWrapperProps) {
  return (
    <div
      className={`fixed top-1/2 left-1/2 z-10 w-fit -translate-y-1/2 -translate-x-1/2 transform ${className}`}
    >
      {children}
    </div>
  );
}

import { addCartItems, changeFlexibleVoucherPrice } from 'lib/commerce-client';
import { Dispatch, SetStateAction } from 'react';
import { useCartContext } from 'lib/cart-context';
import Arrow from 'components/Misc/Arrow';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

const handleAddToCartClick = async (
  variations,
  setShowPopUpCartItemAdded,
  setLoading,
  dispatch,
  locale: string
) => {
  setLoading(true);
  const json = await addCartItems(
    setCombineFalseOnFlexiblePriceItems(variations),
    locale
  );
  if (!json) {
    return;
  }
  const orderItemId = json?.data?.[0]?.id;
  if (orderItemId) {
    dispatch({
      type: 'setFlag',
      cartFlag: true,
    });
  }
  const cartId = json?.data?.[0]?.relationships?.order_id?.data?.id;
  await adaptPricesOnFlexiblePriceItems(
    variations,
    orderItemId,
    cartId,
    locale
  );
  setShowPopUpCartItemAdded(true);
  setLoading(false);
};

const setCombineFalseOnFlexiblePriceItems = (variations) => {
  // If the unit price was overridden adapt the price of the order items in the cart.
  return variations.map((variation) => {
    if (variation?.field_has_flexible_price) {
      return { ...variation, meta: { combine: false } };
    } else return variation;
  });
};

const adaptPricesOnFlexiblePriceItems = async (
  variations,
  orderItemId: string,
  cartId: string,
  locale: string
) => {
  // If the unit price was overridden adapt the price of the order items in the cart.
  const flexiblePriceVariations = variations.filter(
    (variation) => variation?.overridden_unit_price
  );
  if (flexiblePriceVariations?.length > 0) {
    // If the unit price was overridden then we know that we only have one variation selected.
    await changeFlexibleVoucherPrice(
      'product-variation--voucher',
      orderItemId,
      variations?.[0].price?.number,
      cartId,
      locale
    );
  }
};

interface AddToCartStepProps {
  selectedVariations: Array<any>;
  setShowPopUpCartItemAdded: Dispatch<SetStateAction<boolean>>;
  isValidProductSelection: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function AddToCartStep({
  selectedVariations,
  setShowPopUpCartItemAdded,
  isValidProductSelection,
  setLoading,
}: AddToCartStepProps) {
  const { dispatch } = useCartContext();
  const { locale } = useRouter();
  const intl = useIntl();

  return (
    <button
      disabled={!isValidProductSelection}
      onClick={() =>
        handleAddToCartClick(
          selectedVariations,
          setShowPopUpCartItemAdded,
          setLoading,
          dispatch,
          locale
        )
      }
      className={`align-middle text-xl uppercase leading-10 lg:whitespace-nowrap`}
    >
      <Arrow disabled={!isValidProductSelection}>
        {intl.formatMessage({
          defaultMessage: 'In den Warenkorb',
          id: 'DjaYzA',
        })}
      </Arrow>
    </button>
  );
}

import ErrorMessage from 'components/Misc/ErrorMessage';
import LoadingSpinner from 'components/Misc/LoadingSpinner';
import { convertToUTCTime, getStartAndEndDate } from 'lib/date';
import { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import ArrowButton from 'components/Misc/ArrowButton';

interface CalendarStep {
  setSelectedDate;
  selectedDate: Date;
  selectedVariations;
  getAvailableDates?;
  convertAvailableDates?;
  loadInitialDates: boolean;
  minDate?: Date;
  preloadYear?: boolean;
}

export default function CalendarStep({
  setSelectedDate,
  selectedDate,
  selectedVariations,
  getAvailableDates,
  convertAvailableDates,
  loadInitialDates = true,
  preloadYear = false,
  minDate = new Date(),
}) {
  const [availableDates, setAvailableDates] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shownMonth, setShownMonth] = useState(new Date());
  const { locale } = useRouter();

  // Register locale for the calendar.
  switch (locale) {
    case 'en':
      registerLocale('en', enUS);
      break;
    default:
      registerLocale('de', de);
  }
  useEffect(() => {
    if (loadInitialDates) {
      const getDates = async () => {
        setLoading(true);
        const startAndEndDate = getStartAndEndDate(
          selectedDate ?? new Date(),
          preloadYear
        );
        const dateResponse = await getAvailableDates(
          selectedVariations,
          startAndEndDate
        );
        if (dateResponse) {
          setAvailableDates(
            convertAvailableDates(dateResponse, selectedVariations)
          );
        } else {
          setError(
            <FormattedMessage
              defaultMessage={`Beim Laden der Termine ist ein Fehler aufgetreten.`}
              id="gb6k61"
            />
          );
        }
        setLoading(false);
      };
      getDates();
    } else {
      setLoading(false);
    }
  }, [shownMonth, selectedVariations]);

  return (
    <div className="react-datepicker-container">
      {error && (
        <ErrorMessage
          message=<FormattedMessage
            defaultMessage={`Beim Laden der Termine ist ein Fehler aufgetreten.`}
            id="gb6k61"
          />
        ></ErrorMessage>
      )}
      {loading && (
        <div className="left-0 top-0 z-10 flex h-full w-full items-center justify-center p-4">
          <LoadingSpinner size={10} />
        </div>
      )}
      {!preloadYear && !loading && !error && (
        <DatePicker
          peekNextMonth={false}
          selected={selectedDate}
          minDate={minDate}
          locale={locale}
          onChange={(changedDate) => {
            if (changedDate.getMonth() !== shownMonth.getMonth()) {
              setLoading(true);
              setShownMonth(changedDate);
            }
            setSelectedDate(changedDate);
          }}
          onMonthChange={(date) => {
            setLoading(true);
            setShownMonth(date);
            setSelectedDate(date);
          }}
          includeDates={availableDates}
          inline
        />
      )}
      {preloadYear &&
        !loading &&
        !error &&
        availableDates?.length > 0 &&
        availableDates.map((date: Date, index: number) => {
          date.setHours(23);
          date.setMinutes(59);
          date.setSeconds(59);
          if (date < minDate) {
            return null;
          }
          return (
            <li
              key={index}
              className="grid grid-cols-1 gap-1 lg:grid-cols-[230px_1fr] lg:gap-16"
            >
              <span
                className={`flex items-center whitespace-nowrap ${
                  date == selectedDate && `font-bold`
                }`}
              >
                {date.toLocaleDateString(locale, {
                  weekday: 'long',
                  day: 'numeric',
                  month: 'long',
                })}
              </span>
              <ArrowButton
                onClick={() => {
                  setSelectedDate(date);
                }}
              >
                <FormattedMessage
                  defaultMessage={`Tag auswählen`}
                  id="4wQkJJ"
                />
              </ArrowButton>
            </li>
          );
        })}
      {preloadYear && !loading && !error && availableDates?.length == 0 && (
        <div>
          <FormattedMessage
            defaultMessage={`Keine Tickets verfügbar`}
            id="x5Qfqk"
          />
        </div>
      )}
    </div>
  );
}

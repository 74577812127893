import Amount from 'components/Misc/Amount';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Returns whether the price is between the min and max of the product variation.
 *
 * @param variation
 *  The product variation that contains the min and max (and price).
 * @param price
 *  If no price is passed use the variations price.
 * @returns
 *  True if the price is between min and max, false otherwise.
 */
export const isValidPrice = (variation, price = variation?.price?.number) => {
  const minPrice = parseFloat(variation?.field_minimum_price?.number);
  const maxPrice = parseFloat(variation?.field_maximum_price?.number);
  return price >= minPrice && price <= maxPrice;
};

interface QuantityStepProps {
  productVariations: Array<any>;
  selectedVariations: Array<any>;
  setSelectedVariations: Dispatch<SetStateAction<Array<any>>>;
  setIsValidProductSelection: Dispatch<SetStateAction<boolean>>;
  minQuantity?: number;
  maxQuantity?: number;
  onQuantityChange?: () => void;
}

export default function QuantityStep({
  productVariations,
  selectedVariations,
  setSelectedVariations,
  setIsValidProductSelection,
  maxQuantity = 1000,
  minQuantity = 1,
  onQuantityChange,
}: QuantityStepProps) {
  const handleQuantityChange = (changedVariation, newQuantity) => {
    const changedVariations = selectedVariations.map((variation) => {
      if (variation.id === changedVariation.id) {
        return { ...changedVariation, quantity: newQuantity };
      } else return variation;
    });
    setSelectedVariations(changedVariations);
    if (onQuantityChange) {
      onQuantityChange();
    }
  };

  const handlePriceChange = (event, changedVariation) => {
    // If the price on a flexible price variation is changed mark it with overridden_unit_price.
    const changedVariations = selectedVariations.map((variation) => {
      if (variation.id === changedVariation.id) {
        // (De)activates the "Add to cart" button.
        setIsValidProductSelection(
          isValidPrice(variation, event?.target?.value)
        );
        return {
          ...changedVariation,
          price: { number: event?.target?.value },
          overridden_unit_price: true,
        };
      } else return variation;
    });
    setSelectedVariations(changedVariations);
  };
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [selectedVariations]);

  return (
    <div>
      {productVariations?.length > 1 && (
        <h3 className="mb-0 py-4 text-h3-m uppercase lg:py-0 lg:text-h3-d">
          <FormattedMessage defaultMessage={`Anzahl`} id="/Qe8OL" />
        </h3>
      )}
      {selectedVariations.map((variation) => (
        <div key={variation.id} className="my-5 lg:my-14">
          <Amount
            item={variation}
            quantity={variation?.quantity ?? 1}
            onQuantityChange={handleQuantityChange}
            label={
              productVariations?.length > 1 ? (
                variation.title
              ) : (
                <FormattedMessage defaultMessage={`Anzahl`} id="/Qe8OL" />
              )
            }
            min={minQuantity}
            max={maxQuantity}
          />
          <div className="pt-5 font-bold lg:float-right lg:pt-0">
            <span className="peer-invalid:text-primary">CHF </span>
            {variation.field_has_flexible_price ? (
              <input
                ref={inputRef}
                autoFocus
                className="peer w-16 border-0 border-b-2 border-secondary bg-transparent p-0 text-right text-lg invalid:border-primary invalid:text-primary focus:border-primary focus:ring-0 focus-visible:outline-none"
                type="number"
                onChange={(event) => handlePriceChange(event, variation)}
                min={parseFloat(variation?.field_minimum_price?.number)}
                max={parseFloat(variation?.field_maximum_price?.number)}
                value={parseFloat(variation?.price?.number)}
              ></input>
            ) : (
              parseFloat(variation?.price?.number) * (variation?.quantity ?? 1)
            )}
            <span>.–</span>
          </div>

          <br />
          {variation.field_has_flexible_price && (
            <div
              className={`mt-2 font-normal text-primary lg:whitespace-nowrap ${
                isValidPrice(variation) && 'invisible'
              }`}
            >
              <FormattedMessage
                defaultMessage={`Bitte geben Sie einen Wert zwischen CHF {minimum}.– und CHF {maximum}.– ein.`}
                id="q4KC/n"
                values={{
                  minimum: parseFloat(variation?.field_minimum_price?.number),
                  maximum: parseFloat(variation?.field_maximum_price?.number),
                }}
              />
            </div>
          )}
          {productVariations?.length === 1 &&
            variation?.field_description?.value && (
              <div className="mt-12">
                <div className="my-2 inline-block font-bold lg:my-5">
                  <FormattedMessage
                    defaultMessage={`Detailinformationen`}
                    id="2plzn8"
                  />
                </div>
                <div
                  className="prose"
                  dangerouslySetInnerHTML={{
                    __html: variation.field_description.value,
                  }}
                />
              </div>
            )}
        </div>
      ))}
    </div>
  );
}

import { ReactElement } from 'react';
import { GetStaticPathsResult, GetStaticPropsResult } from 'next';
import { DrupalNode, DrupalMenuLinkContent, DrupalView } from 'next-drupal';
import { WebformObject } from 'nextjs-drupal-webform';
import { drupal } from 'lib/drupal';
import Layout from 'components/layouts/Layout';
import getMenus from 'lib/get-menus';
import { getParams } from 'lib/get-params';
import { loadViews } from 'lib/get-views';
import { getWebforms } from 'lib/get-webforms';
import { loadVariations } from 'lib/get-product-variations';
import NodeBasicPage from 'components/nodes/NodeBasicPage';
import VoucherProduct from 'components/products/VoucherProduct';
import TicketProduct from 'components/products/TicketProduct';
import ReservationProduct from 'components/products/ReservationProduct';
import RetailProduct from 'components/products/RetailProduct';
import PackageProduct from 'components/products/PackageProduct';
import CourseProduct from 'components/products/CourseProduct';

const ENTITY_TYPES = [
  'node--page',
  'product--voucher',
  'product--ticket',
  'product--retail-products',
  'product--reservation',
  'product--package',
];

interface NodePageProps {
  entity: DrupalNode;
  views?: Array<DrupalView>;
  menus: {
    main: DrupalMenuLinkContent[];
    footer: DrupalMenuLinkContent[];
    stickyShop: DrupalMenuLinkContent[];
  };
  webforms: WebformObject;
}

export default function NodePage({ entity, views, webforms }: NodePageProps) {
  if (!entity) return null;
  const productVariations = entity?.variations;

  return (
    <>
      {entity.type === 'node--page' && (
        <NodeBasicPage node={entity} views={views} webforms={webforms} />
      )}
      {entity.type === 'product--voucher' && (
        <VoucherProduct
          product={entity}
          productVariations={productVariations}
        />
      )}
      {entity.type === 'product--reservation' && (
        <ReservationProduct
          product={entity}
          productVariations={productVariations}
        />
      )}
      {entity.type === 'product--ticket' && (
        <TicketProduct product={entity} productVariations={productVariations} />
      )}
      {entity.type === 'product--retail-products' && (
        <RetailProduct product={entity} productVariations={productVariations} />
      )}
      {entity.type === 'product--package' && (
        <PackageProduct
          product={entity}
          productVariations={productVariations}
        />
      )}
      {entity.type === 'product--course' && (
        <CourseProduct product={entity} productVariations={productVariations} />
      )}
    </>
  );
}

NodePage.getLayout = (page: ReactElement) => {
  return <Layout {...page.props}>{page}</Layout>;
};

export async function getStaticPaths(context): Promise<GetStaticPathsResult> {
  // When this is true (in preview environments) don't
  // prerender any static pages
  // (faster builds, but slower initial page load)
  if (
    process.env.SKIP_BUILD_STATIC_GENERATION &&
    process.env.SKIP_BUILD_STATIC_GENERATION === 'true'
  ) {
    return {
      paths: [],
      fallback: 'blocking',
    };
  }

  return {
    paths: await drupal.getStaticPathsFromContext(ENTITY_TYPES, context),
    fallback: 'blocking',
  };
}

export async function getStaticProps(
  context
): Promise<GetStaticPropsResult<NodePageProps>> {
  const path = await drupal.translatePathFromContext(context);

  if (!path) {
    return {
      notFound: true,
    };
  }

  // Check for redirect.
  if (path.redirect?.length) {
    const [redirect] = path.redirect;
    return {
      redirect: {
        destination: redirect.to,
        permanent: redirect.status === '301',
      },
    };
  }

  const entity = await drupal.getResourceFromContext<DrupalNode>(
    path,
    context,
    {
      // check lib/get-params.ts for jsonapi includes of entity fields
      params: getParams(path.jsonapi.resourceName),
    }
  );

  // At this point, we know the path exists and it points to a resource.
  // If we receive an error, it means something went wrong on Drupal.
  // We throw an error to tell revalidation to skip this for now.
  // Revalidation can try again on next request.
  if (!entity) {
    throw new Error(`Failed to fetch resource: ${path.jsonapi.individual}`);
  }

  // If we're not in preview mode and the resource is not published,
  // Return page not found.
  if (!context.preview && entity?.status === false) {
    return {
      notFound: true,
    };
  }

  // Load additional ressources if needed.
  const views = await loadViews(entity, context);
  const menus = await getMenus(context);
  const webforms = await getWebforms(entity, context);

  return {
    props: {
      entity,
      views,
      menus,
      webforms,
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 10, // In seconds
  };
}

import Radio from 'components/forms/Radio';

export default function VariationStep({
  productVariations,
  setSelectedVariations,
  selectedVariation,
}) {
  return (
    <div className="mt-12 bg-secondary px-6 py-11 text-tt-white lg:mt-16 lg:px-14">
      <div className="grid grid-cols-1 gap-4 lg:gap-8 2xl:grid-cols-[repeat(2,_minmax(0,_max-content))]">
        {productVariations?.map((variation) => {
          return (
            <Radio
              key={variation.id}
              id={`variation-${variation.id}`}
              name="variationselection"
              onClick={() => setSelectedVariations([variation])}
              checked={variation.id == selectedVariation.id}
              onChange={() => {}}
              label={variation.title}
            />
          );
        })}
      </div>
      {selectedVariation?.field_description?.value && (
        <div
          className="prose mt-8 text-tt-white"
          dangerouslySetInnerHTML={{
            __html: selectedVariation.field_description.value,
          }}
        />
      )}
    </div>
  );
}

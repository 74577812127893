export default function TicketQuantityStep({
  selectedVariations,
  setSelectedVariations,
  setSelectedDate,
}) {
  const transformedVariations = selectedVariations?.reduce(function (
    product,
    cur
  ) {
    // Get the index of the key-value pair.
    var occurs = product?.reduce(function (itemsAmount, item, index) {
      return item.title === cur.title ? index : itemsAmount;
    }, -1);

    cur.price.id = cur.id;
    cur.price.weekday = cur.field_weekday;
    cur.price.printName = cur.field_print_name;

    // If the name is found,
    if (occurs >= 0) {
      // append the current value to its list of values.
      product[occurs].price = product[occurs]?.price?.concat(cur.price);
    } else {
      // add the current item to o (but make sure the value is an array).
      var obj = {
        title: cur.title,
        price: [cur.price],
        quantity: cur.quantity,
      };
      product = product.concat([obj]);
    }

    return product;
  },
  []);

  const handleQuantityChange = (changedVariation, quantity) => {
    const variationsDataToSet = [...selectedVariations];
    setSelectedDate(null);

    // Map over the price variation selected
    changedVariation?.price?.map((priceItem) => {
      // Filter the price variation from productVariation to get the original data format
      const filterVariation = selectedVariations?.filter(
        (variation) => variation?.id == priceItem?.id
      );

      // Check if the filtered variation already exists in the state values set
      const selectedVariationExists = selectedVariations?.filter(
        (selectedVar) => selectedVar?.id === filterVariation[0]?.id
      );

      // Increase the quantity of already set state values
      if (selectedVariationExists?.length) {
        variationsDataToSet?.map((selectItem) => {
          if (filterVariation[0]?.id === selectItem.id) {
            selectItem.quantity = Math.max(quantity, 0);
          }
        });

        //  Add the variation if it's not already a state value
      } else {
        filterVariation[0].quantity = Math.max(quantity, 0);
        variationsDataToSet.push(filterVariation[0]);
      }
    });

    //  Add the variation data on parent component for cart to be use
    if (variationsDataToSet?.length > 0) {
      setSelectedVariations(variationsDataToSet);
    }
  };

  return (
    <div className="mt-16">
      {transformedVariations?.map((item, key) => {
        return (
          <div key={key}>
            <div className="hidden lg:my-12 lg:flex lg:justify-between">
              <div>{item.title}</div>
              <div className="lg:flex lg:w-[60%] lg:gap-6 lg:text-base">
                {item.price?.map((priceItem, index) => {
                  return (
                    <div key={index} className="lg:w-[40%]">
                      <div
                        className={`${
                          key === 0
                            ? 'lg:mt-[-35px] lg:block lg:text-base-m'
                            : 'lg:hidden'
                        }`}
                      >
                        {priceItem.printName}
                      </div>
                      <div className={`${key === 0 ? 'lg:mt-4' : ''}`}>
                        {priceItem?.formatted}
                      </div>
                    </div>
                  );
                })}

                {
                  <span
                    className={`my-14 flex items-center justify-center font-bold lg:my-0 lg:w-[25%] lg:items-start`}
                  >
                    <button
                      className="ml-7 mr-5 text-xl lg:text-base"
                      onClick={() =>
                        handleQuantityChange(
                          item,
                          parseInt(item.quantity, 10) - 1
                        )
                      }
                    >
                      -
                    </button>
                    {item.quantity}
                    <button
                      className="ml-5 text-xl lg:text-base"
                      onClick={() =>
                        handleQuantityChange(
                          item,
                          parseInt(item.quantity, 10) + 1
                        )
                      }
                    >
                      +
                    </button>
                  </span>
                }
              </div>
            </div>

            <div className=" flex justify-between text-base-m lg:hidden">
              <div className="my-4 flex flex-col gap-1">
                <div className="font-bold">{item.title}</div>
                {item.price?.map((priceItem, index) => {
                  return (
                    <div key={index} className="flex flex-col">
                      <div className="flex w-[50%] flex-col">
                        <div className="">{priceItem.printName}</div>
                        <div className="font-bold ">{priceItem?.formatted}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <span
                className={`flex w-[40%] items-center justify-center font-bold`}
              >
                <button
                  className="ml-7 mr-5 text-xl"
                  onClick={() =>
                    handleQuantityChange(item, parseInt(item.quantity, 10) - 1)
                  }
                >
                  -
                </button>
                {item.quantity}
                <button
                  className="ml-5 text-lg"
                  onClick={() =>
                    handleQuantityChange(item, parseInt(item.quantity, 10) + 1)
                  }
                >
                  +
                </button>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

import Input from 'components/forms/Input';
import Radio from 'components/forms/Radio';
import RadioGroup from 'components/forms/RadioGroup';
import ArrowButton from 'components/Misc/ArrowButton';
import ErrorMessage from 'components/Misc/ErrorMessage';
import { addCartItems } from 'lib/commerce-client';
import { convertToDateAndTime, convertToUTCTime } from 'lib/date';
import {
  convertAvailableTimeSlots,
  getAvailableReservationTimeSlots,
} from 'lib/reservation-client';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GENDER_PREFERENCE } from '../GenderPreferenceStep/GenderPreferenceStep';
import AnnotationInput from './AnnotationInput';

const handleAvailabilityClick = async (
  selectedDate,
  genderPreference,
  selectedVariation,
  setAvailableTimeSlots,
  setLoading,
  setError
) => {
  setError('');
  setLoading(true);
  const timeSlotResponse = await getAvailableReservationTimeSlots(
    selectedVariation.id,
    selectedDate,
    genderPreference,
    selectedVariation.quantity
  );

  if (!timeSlotResponse) {
    setLoading(false);
    setError(
      <FormattedMessage
        defaultMessage={`Beim Abrufen der verfügbaren Termine ist ein Fehler aufgetreten.`}
        id="FBcXda"
      />
    );
    return;
  }

  const availableTimeSlots = convertAvailableTimeSlots(timeSlotResponse);
  setAvailableTimeSlots(availableTimeSlots);
  setLoading(false);
};

const handleReservationClick = async (
  variation,
  setShowPopUpCartItemAdded,
  setLoading,
  setError,
  locale: string
) => {
  setError('');
  setLoading(true);
  const response = await addCartItems([variation], locale);

  if (!response) {
    setLoading(false);
    setError(
      <FormattedMessage
        defaultMessage={`Beim Hinzufügen zum Warenkorb ist ein Fehler aufgetreten. Bitte überprüfen Sie die Verfügbarkeit des gewählten Termins.`}
        id="75ni7u"
      />
    );
    return;
  }
  setShowPopUpCartItemAdded(true);
  setLoading(false);
};

export default function TimeStep({
  selectedDate,
  setLoading,
  setShowPopUpCartItemAdded,
  selectedVariation,
  bookFor,
}) {
  const [selectedGender, setSelectedGender] = useState(
    GENDER_PREFERENCE.Neutral
  );
  const [remarks, setRemarks] = useState('');
  const [availableTimeSlots, setAvailableTimeSlots] = useState(null);
  const [error, setError] = useState('');
  const { locale } = useRouter();

  useEffect(() => {
    setAvailableTimeSlots(null);
  }, [selectedDate]);

  return (
    <div>
      <div className="mb-24">
        <RadioGroup>
          {(Object.values(GENDER_PREFERENCE) as Array<any>).map(
            (gender, index) => (
              <Radio
                key={index}
                id={`gender-${gender.value}`}
                name="genderselection"
                onClick={() => setSelectedGender(gender)}
                checked={selectedGender.value == gender.value}
                onChange={() => {}}
                label={gender.label}
              />
            )
          )}
        </RadioGroup>
        <div className="mt-24" />
        <AnnotationInput
          id="remarks"
          label=<FormattedMessage
            defaultMessage={`Anmerkungen zur Behandlung`}
            id="rMl42v"
          />
          onChange={(event) => setRemarks(event.target.value)}
        />
      </div>

      <ArrowButton
        onClick={() =>
          handleAvailabilityClick(
            selectedDate,
            selectedGender,
            selectedVariation,
            setAvailableTimeSlots,
            setLoading,
            setError
          )
        }
      >
        <FormattedMessage defaultMessage={`Verfügbarkeit prüfen`} id="RUcNm8" />
      </ArrowButton>
      <div className="mt-5">
        <FormattedMessage
          defaultMessage={`Wann möchten Sie die Behandlung in Anspruch nehmen?`}
          id="lVibW0"
        />
      </div>
      {error && (
        <div className="my-5">
          <ErrorMessage message={error} />
        </div>
      )}
      <ul className="my-5 flex flex-col gap-8">
        {availableTimeSlots?.length > 0 &&
          availableTimeSlots.map((timeSlot, index) => (
            <li
              key={index}
              className="grid grid-cols-1 gap-1 lg:grid-cols-[230px_1fr] lg:gap-16"
            >
              <span className="flex items-center whitespace-nowrap">
                <FormattedMessage
                  defaultMessage={`{startTime} - {endTime} Uhr`}
                  id="AOte8p"
                  values={{
                    startTime: convertToUTCTime(timeSlot?.startTime),
                    endTime: convertToUTCTime(timeSlot?.endTime),
                  }}
                />
              </span>
              <ArrowButton
                onClick={() => {
                  // Use selected variation and add information of selected timeslot to reserve it.
                  const selectedTimeSlotVariation = {
                    ...selectedVariation,
                    quantity: 1,
                    meta: {
                      combine: false,
                      fields: {
                        field_gender_preference: selectedGender.value,
                        field_start_date: convertToDateAndTime(
                          timeSlot?.startTime
                        ),
                        field_end_date: convertToDateAndTime(timeSlot?.endTime),
                        field_booking_for: bookFor.value,
                        field_remarks: remarks,
                      },
                    },
                  };
                  handleReservationClick(
                    selectedTimeSlotVariation,
                    setShowPopUpCartItemAdded,
                    setLoading,
                    setError,
                    locale
                  );
                }}
              >
                <FormattedMessage
                  defaultMessage={`Behandlung buchen`}
                  id="CiCF4o"
                />
              </ArrowButton>
            </li>
          ))}
        {availableTimeSlots?.length < 1 && (
          <strong>
            <FormattedMessage
              defaultMessage={`Basierend auf den ausgewählten Präferenzen konnte leider keine
            verfügbare Person für diesen Tag gefunden werden. <br />
            <br />
            Bitte wählen Sie einen anderen Tag oder eine andere Präferenz.`}
              id="Rb1uGs"
            />
          </strong>
        )}
      </ul>
    </div>
  );
}

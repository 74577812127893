import Amount from 'components/Misc/Amount';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

interface SpecialEventTicketQuantityStepProps {
  productVariations: Array<any>;
  selectedVariations: Array<any>;
  setSelectedVariations: Dispatch<SetStateAction<Array<any>>>;
  setIsValidProductSelection: Dispatch<SetStateAction<boolean>>;
  minQuantity?: number;
  maxQuantity?: number;
}

export default function SpecialEventTicketQuantityStep({
  productVariations,
  selectedVariations,
  setSelectedVariations,
  setIsValidProductSelection,
  maxQuantity = 1000,
  minQuantity = 1,
}: SpecialEventTicketQuantityStepProps) {
  const handleQuantityChange = (changedVariation, newQuantity) => {
    const changedVariations = selectedVariations.map((variation) => {
      if (variation.id === changedVariation.id) {
        return { ...changedVariation, quantity: newQuantity };
      } else return variation;
    });
    setSelectedVariations(changedVariations);
    setIsValidProductSelection(false);
  };

  return (
    <div>
      {productVariations?.length > 1 && (
        <h3 className="mb-0 py-4 text-h3-m uppercase lg:py-0 lg:text-h3-d">
          <FormattedMessage defaultMessage={`Anzahl`} id="/Qe8OL" />
        </h3>
      )}
      {selectedVariations?.map((variation) => (
        <div key={variation.id} className="my-5 lg:my-14">
          <Amount
            item={variation}
            quantity={variation?.quantity ?? 1}
            onQuantityChange={handleQuantityChange}
            label={
              productVariations?.length > 1 ? (
                variation.title
              ) : (
                <FormattedMessage defaultMessage={`Anzahl`} id="/Qe8OL" />
              )
            }
            min={minQuantity}
            max={maxQuantity}
          />
          <div className="pt-5 font-bold lg:float-right lg:pt-0">
            <span className="peer-invalid:text-primary">CHF </span>
            {parseFloat(variation?.price?.number) * (variation?.quantity ?? 1)}
            <span>.–</span>
          </div>
        </div>
      ))}
    </div>
  );
}

import Steps from 'components/Commerce/Steps';
import AddToCartStep from 'components/Commerce/Steps/AddToCartStep';
import { useEffect, useState } from 'react';
import PopUpCartItemAdded from 'components/Commerce/PopUpCartItemAdded';
import LoadingSpinner from 'components/Misc/LoadingSpinner';
import ProductDetails from 'components/Commerce/Steps/ProductDetails';
import HeaderSubPage from 'components/HeaderSubPage';
import {
  addSelectedDateToVariations,
  hasAvailableTicketQuantity,
  getAvailableDatesForTickets,
} from 'lib/ticket-client';
import { convertDateYmd } from 'lib/date';
import LoadingSpinnerWrapper from 'components/Misc/LoadingSpinnerWrapper';
import SpecialEventTicketQuantityStep from 'components/Commerce/Steps/SpecialEventTicketQuantityStep';
import ArrowButton from 'components/Misc/ArrowButton';
import ErrorMessage from 'components/Misc/ErrorMessage';

interface SpecialEventTicketProps {
  product: any;
  productVariations: any;
}

function initializeQuantity(productVariations) {
  const initializedVariations = productVariations?.map((variation) => ({
    ...variation,
    quantity: 1,
  }));
  return initializedVariations;
}

export default function TicketProduct({
  product,
  productVariations,
  ...props
}: SpecialEventTicketProps) {
  const [selectedDateVariations, setSelectedDateVariations] = useState(
    addSelectedDateToVariations(
      new Date(product?.field_special_event_date),
      initializeQuantity(productVariations)
    )
  );
  const [showPopUpCartItemAdded, setShowPopUpCartItemAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidProductSelection, setIsValidProductSelection] = useState(false);
  const [error, setError] = useState(null);

  async function checkTicketAvailabilty() {
    setLoading(true);
    setError(null);
    const convertedYmdDate = convertDateYmd(
      new Date(product?.field_special_event_date)
    );
    const response = await getAvailableDatesForTickets(selectedDateVariations, {
      startDate: convertedYmdDate,
      endDate: convertedYmdDate,
    });

    if (!response) {
      setError('Anfrage fehlgeschlagen, bitte versuchen Sie es erneut');
      setLoading(false);
      return;
    }

    const availabiltyForDate =
      response?.body_content?.availabilities?.[convertedYmdDate];
    const isAvailable = hasAvailableTicketQuantity(
      availabiltyForDate,
      selectedDateVariations
    );
    if (isAvailable) {
      setIsValidProductSelection(true);
    } else {
      setError('Die gewählte Anzaht Tickets ist leider nicht mehr verfügbar.');
    }
    setLoading(false);
  }

  return (
    <article {...props} className="bg-tertiary">
      <PopUpCartItemAdded
        show={showPopUpCartItemAdded}
        setShow={setShowPopUpCartItemAdded}
      />
      {loading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner size={10} />
        </LoadingSpinnerWrapper>
      )}
      <div data-product-type="TicketProduct" className="relative">
        <div className="mx-auto w-full">
          <div className="relative sm:overflow-hidden">
            <HeaderSubPage
              title={product?.title}
              imageUrl={product?.field_image?.field_media_image?.uri?.url}
              videoUrl={null}
            />
            <Steps>
              <div>
                <ProductDetails product={product} />
                <SpecialEventTicketQuantityStep
                  productVariations={productVariations}
                  selectedVariations={selectedDateVariations}
                  setSelectedVariations={setSelectedDateVariations}
                  setIsValidProductSelection={setIsValidProductSelection}
                />
                <ArrowButton onClick={checkTicketAvailabilty}>
                  Verfügbarkeit prüfen
                </ArrowButton>
                {error && (
                  <div className="mt-4">
                    <ErrorMessage message={error} />
                  </div>
                )}
              </div>
              <AddToCartStep
                selectedVariations={selectedDateVariations}
                setShowPopUpCartItemAdded={setShowPopUpCartItemAdded}
                isValidProductSelection={isValidProductSelection}
                setLoading={setLoading}
              />
            </Steps>
          </div>
        </div>
      </div>
    </article>
  );
}

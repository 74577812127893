import Steps from 'components/Commerce/Steps';
import { useState } from 'react';
import PopUpCartItemAdded from 'components/Commerce/PopUpCartItemAdded';
import CalendarStep from 'components/Commerce/Steps/CalendarStep';
import LoadingSpinner from 'components/Misc/LoadingSpinner';
import { getDefaultVariation } from 'lib/variations';
import QuantityStep from 'components/Commerce/Steps/QuantityStep';
import GenderPreferenceStep from 'components/Commerce/Steps/GenderPreferenceStep';
import ProductDetails from 'components/Commerce/Steps/ProductDetails';
import BookForStep from 'components/Commerce/Steps/BookForStep';
import StepHeadline from 'components/Commerce/Steps/StepHeadline';
import AddToCartStep from 'components/Commerce/Steps/AddToCartStep';
import CheckAvailabilityStep from 'components/Commerce/Steps/CheckAvailabilityStep';
import {
  BOOK_FOR,
  BookFor,
} from 'components/Commerce/Steps/BookForStep/BookForStep';
import HeaderSubPage from 'components/HeaderSubPage';
import LoadingSpinnerWrapper from 'components/Misc/LoadingSpinnerWrapper';
import {
  GenderPreference,
  GENDER_PREFERENCE,
} from 'components/Commerce/Steps/GenderPreferenceStep/GenderPreferenceStep';
import { FormattedMessage } from 'react-intl';

interface TicketProductProps {
  product: any;
  productVariations: any;
}

export default function PackageProduct({
  product,
  productVariations,
  ...props
}: TicketProductProps) {
  const [selectedVariations, setSelectedVariations] = useState([
    getDefaultVariation(product, productVariations),
  ]);
  const [isValidProductSelection, setIsValidProductSelection] = useState(false);
  const [showPopUpCartItemAdded, setShowPopUpCartItemAdded] = useState(false);
  const [genderPreference, setGenderPreference] = useState<GenderPreference>(
    GENDER_PREFERENCE.Neutral
  );
  const [bookFor, setBookFor] = useState<BookFor>(BOOK_FOR.myself);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  return (
    <article {...props} className="bg-tertiary">
      <PopUpCartItemAdded
        show={showPopUpCartItemAdded}
        setShow={setShowPopUpCartItemAdded}
      />
      {loading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner size={10} />
        </LoadingSpinnerWrapper>
      )}
      <div data-product-type="TicketProduct" className="relative">
        <div className="mx-auto w-full">
          <div className="relative sm:overflow-hidden">
            <HeaderSubPage
              title={product?.title}
              imageUrl={product?.field_image?.field_media_image?.uri?.url}
              videoUrl={null}
            />
            <Steps>
              <ProductDetails product={productVariations[0]} />
              <div>
                <StepHeadline
                  headline={
                    <FormattedMessage defaultMessage={`Buchung`} id="j0Aazb" />
                  }
                  subheadline={''}
                />
                {/* TODO: Remove valid product selection when amount is changed */}
                <QuantityStep
                  productVariations={productVariations}
                  selectedVariations={selectedVariations}
                  setIsValidProductSelection={null}
                  setSelectedVariations={setSelectedVariations}
                  minQuantity={
                    selectedVariations?.[0]?.field_minimum_persons || 1
                  }
                  maxQuantity={
                    selectedVariations?.[0]?.field_maximum_persons || 2
                  }
                  onQuantityChange={() => {
                    setIsValidProductSelection(false);
                  }}
                />
                <BookForStep bookFor={bookFor} setBookFor={setBookFor} />
              </div>
              <div>
                <StepHeadline
                  headline={
                    <FormattedMessage defaultMessage={`Person`} id="1mnmBr" />
                  }
                  subheadline={
                    <FormattedMessage
                      defaultMessage={`Welche Person soll die Behandlung durchführen?`}
                      id="pEgKOH"
                    />
                  }
                />
                <GenderPreferenceStep
                  genderPreference={genderPreference}
                  setGenderPreference={setGenderPreference}
                />
              </div>
              <div>
                <StepHeadline
                  headline={
                    <FormattedMessage
                      defaultMessage={`Aufenthalt`}
                      id="j6ajtJ"
                    />
                  }
                  subheadline={
                    <FormattedMessage
                      defaultMessage={`Bitte wählen Sie die gewünschte Option aus. Detailinformationen erhalten Sie unten.`}
                      id="a/V2V8"
                    />
                  }
                />
                <div className="mt-12 lg:mt-16" />
                <CalendarStep
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedVariations={selectedVariations}
                  getAvailableDates={() => {}}
                  convertAvailableDates={() => {}}
                  loadInitialDates={false}
                />
                <CheckAvailabilityStep
                  selectedVariation={selectedVariations[0]}
                  selectedDate={selectedDate}
                  genderPreference={genderPreference}
                  bookFor={bookFor}
                  setIsValidProductSelection={setIsValidProductSelection}
                  setLoading={setLoading}
                  setSelectedVariations={setSelectedVariations}
                  isValidProductSelection={isValidProductSelection}
                />
              </div>
              {isValidProductSelection && (
                <AddToCartStep
                  setLoading={setLoading}
                  isValidProductSelection={isValidProductSelection}
                  selectedVariations={selectedVariations}
                  setShowPopUpCartItemAdded={setShowPopUpCartItemAdded}
                />
              )}
            </Steps>
          </div>
        </div>
      </div>
    </article>
  );
}

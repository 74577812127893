import Steps from 'components/Commerce/Steps';
import { useState } from 'react';
import PopUpCartItemAdded from 'components/Commerce/PopUpCartItemAdded';
import VariationStep from 'components/Commerce/Steps/VariationStep';
import CalendarStep from 'components/Commerce/Steps/CalendarStep';
import TimeStep from 'components/Commerce/Steps/TimeStep';
import LoadingSpinner from 'components/Misc/LoadingSpinner';
import { getDefaultVariation } from 'lib/variations';
import BookForStep from 'components/Commerce/Steps/BookForStep';
import {
  BookFor,
  BOOK_FOR,
} from 'components/Commerce/Steps/BookForStep/BookForStep';
import ProductDetails from 'components/Commerce/Steps/ProductDetails';
import HeaderSubPage from 'components/HeaderSubPage';
import {
  convertAvailableReservationDates,
  getAvailableDatesForReservations,
} from 'lib/reservation-client';
import LoadingSpinnerWrapper from 'components/Misc/LoadingSpinnerWrapper';

interface ReservationProductProps {
  product: any;
  productVariations: any;
}

export default function ReservationProduct({
  product,
  productVariations,
  ...props
}: ReservationProductProps) {
  const [selectedVariations, setSelectedVariations] = useState([
    getDefaultVariation(product, productVariations),
  ]);
  const [showPopUpCartItemAdded, setShowPopUpCartItemAdded] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookFor, setBookFor] = useState<BookFor>(BOOK_FOR.myself);
  const [loading, setLoading] = useState(false);

  return (
    <article {...props} className="bg-tertiary">
      <PopUpCartItemAdded
        show={showPopUpCartItemAdded}
        setShow={setShowPopUpCartItemAdded}
      />
      {loading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner size={10} />
        </LoadingSpinnerWrapper>
      )}
      <div data-product-type="ReservationProduct" className="relative">
        <div className="mx-auto w-full">
          <div className="relative sm:overflow-hidden">
            <HeaderSubPage
              title={product?.title}
              imageUrl={product?.field_image?.field_media_image?.uri?.url}
              videoUrl={null}
            />
            <Steps>
              <div>
                <ProductDetails product={product} />
                <VariationStep
                  productVariations={productVariations}
                  setSelectedVariations={setSelectedVariations}
                  selectedVariation={selectedVariations?.[0]}
                />
              </div>
              <div>
                <>
                  <BookForStep bookFor={bookFor} setBookFor={setBookFor} />
                </>
                <CalendarStep
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedVariations={selectedVariations}
                  getAvailableDates={getAvailableDatesForReservations}
                  convertAvailableDates={convertAvailableReservationDates}
                />
              </div>
              <TimeStep
                selectedDate={selectedDate}
                bookFor={bookFor}
                setShowPopUpCartItemAdded={setShowPopUpCartItemAdded}
                setLoading={setLoading}
                selectedVariation={selectedVariations?.[0]}
              />
            </Steps>
          </div>
        </div>
      </div>
    </article>
  );
}

import P10NewsTeaser from 'components/paragraphs/P10NewsTeaser';
import P15Webform from 'components/paragraphs/P15Webform/P15Webform';
import Paragraph from 'components/paragraphs/Paragraph';
import { DrupalNode, DrupalParagraph, DrupalView } from 'next-drupal';
import { WebformObject } from 'nextjs-drupal-webform';

interface NodeBasicPageProps {
  node: DrupalNode;
  views: Array<DrupalView>;
  webforms: Array<WebformObject>;
}

export default function NodeBasicPage({
  node,
  views,
  webforms,
  ...props
}: NodeBasicPageProps) {
  let isFirstH1Paragraph = true;
  let viewIndex = 0;
  let webformIndex = 0;

  return (
    <article {...props} className="bg-tertiary">
      {node.field_content?.map((paragraph: DrupalParagraph) => {
        // In case of a news teaser, we need to pass the correct view to the component.
        if (paragraph.type === 'paragraph--news_teaser_reusable') {
          const view = views[viewIndex];
          viewIndex++;
          return <P10NewsTeaser key={paragraph.id} view={view} />;
        }
        if (
          isFirstH1Paragraph &&
          (paragraph.type == 'paragraph--text' ||
            paragraph.type == 'paragraph--text_introduction')
        ) {
          // Set the first occurence of a text or text introduction paragraph to have an h1.
          paragraph.hasH1 = true;
          isFirstH1Paragraph = false;
        }
        if (paragraph.type === 'paragraph--form') {
          const webform = webforms[webformIndex];
          webformIndex++;
          return (
            <P15Webform
              key={paragraph.id}
              paragraph={paragraph}
              webform={webform}
            />
          );
        }
        return <Paragraph key={paragraph.id} paragraph={paragraph} />;
      })}
    </article>
  );
}

import ArrowLink from 'components/Misc/ArrowLink';
import PopUp from 'components/Misc/PopUp';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function PopUpCartItemAdded({ show, setShow }) {
  return (
    <PopUp show={show} setShow={setShow}>
      <div className="curved">
        <FormattedMessage defaultMessage={`Vielen Dank`} id="rDdjq3" />
      </div>
      <div className="my-3 text-xl uppercase">
        <FormattedMessage defaultMessage={`Produkt hinzugefügt`} id="6dOIQH" />
      </div>
      <div className="mb-5 text-base">
        <FormattedMessage
          defaultMessage={`Ihr Produkt wurde zum Warenkorb hinzugefügt.`}
          id="eYt6xb"
        />
      </div>
      <div className="flex flex-col gap-4">
        <ArrowLink href="/commerce/cart" inverted>
          <FormattedMessage defaultMessage={`Zur Kasse`} id="kaSRFA" />
        </ArrowLink>
        <ArrowLink href="/onlineshop" onClick={() => setShow(false)} inverted>
          <FormattedMessage defaultMessage={`Weiter einkaufen`} id="xOzBN1" />
        </ArrowLink>
      </div>
    </PopUp>
  );
}

const url = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL;

// Get the dates for a courses
export async function getAvailableDatesForCourses(variations: Array<any>) {
  const productId = variations?.[0]?.id;
  const response = await fetch(
    `${url}/de/api/courses/classes?product_id=${productId}`,
    {
      method: 'GET',
    }
  );
  if (!response.ok) {
    console.log('Get available dates response status: ' + response.status);
    console.log('Get available dates response: ', await response.json());
    return false;
  } else {
    return await response.json();
  }
}

// Convert the course-availability response to an array of js Dates and filter by available quantity.
export function convertAvailableCourseDates(response, selectedVariations) {
  const bookingsClasses = Object.values(response?.classes) as Array<any>;
  const quantity = selectedVariations[0].quantity;
  const maximumPersons = selectedVariations[0].field_maximum_persons;

  const availableClasses = bookingsClasses
    ?.filter((date) => {
      const availableQuantity = maximumPersons - date?.NumberOfPersons;
      return quantity <= availableQuantity;
    })
    .map((date) => {
      return new Date(Date.parse(date?.TimeSpan?.Start));
    });
  return availableClasses;
}

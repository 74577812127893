import React from 'react';

export default function Steps({ children }) {
  return (
    <div className="relative pt-32">
      {React.Children.map(children, (step, index) => {
        return (
          <div key={index} className="flex">
            <div className="w-1/12 lg:w-1/6">
              <div className="absolute -left-2 flex aspect-square w-1/12 items-center justify-end rounded-full bg-primary text-lg font-bold text-white sm:justify-center sm:text-xl lg:relative lg:left-0 lg:mx-auto lg:w-16">
                <span className="pr-2 pl-1 sm:pr-0 sm:pl-0">{index + 1}</span>
              </div>
              {React.Children.count(children) - 1 !== index && (
                <div className="mx-auto w-0.5 bg-secondary lg:h-full" />
              )}
            </div>
            <div className="w-9/12 pb-14 lg:w-4/6 lg:pb-32">{step}</div>
            <div className="w-2/12 lg:w-2/6"></div>
          </div>
        );
      })}
    </div>
  );
}

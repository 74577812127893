import ArrowButton from 'components/Misc/ArrowButton';
import ErrorMessage from 'components/Misc/ErrorMessage';
import { addCartItems } from 'lib/commerce-client';
import {
  convertDateYmd,
  convertToDateAndTime,
  convertToUTCTime,
} from 'lib/date';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const handleAvailabilityClick = async (
  selectedDate,
  setAvailableTimeSlots,
  availabilities,
  setLoading
) => {
  setLoading(true);

  // Filter booking objects for the selected date.
  const selectedDateString = convertDateYmd(selectedDate);
  const selectedAvailabilities = Object.keys(availabilities)
    .filter((date) => date.startsWith(selectedDateString))
    .map((key) => availabilities[key]);
  setAvailableTimeSlots(selectedAvailabilities);
  setLoading(false);
};

const handleReservationClick = async (
  variation,
  setShowPopUpCartItemAdded,
  setLoading,
  setError,
  locale: string
) => {
  setError('');
  setLoading(true);
  const response = await addCartItems([variation], locale);

  if (!response) {
    setLoading(false);
    setError(
      <FormattedMessage
        defaultMessage={`Beim Hinzufügen zum Warenkorb ist ein Fehler aufgetreten. Bitte überprüfen Sie die Verfügbarkeit des gewählten Termins.`}
        id="75ni7u"
      />
    );
    return;
  }
  setShowPopUpCartItemAdded(true);
  setLoading(false);
};

export default function TimeStepCourse({
  selectedDate,
  setLoading,
  setShowPopUpCartItemAdded,
  selectedVariation,
  bookFor,
  availabilities,
}) {
  const [availableTimeSlots, setAvailableTimeSlots] = useState(null);
  const [error, setError] = useState('');
  const { locale } = useRouter();

  useEffect(() => {
    setAvailableTimeSlots(null);
  }, [selectedDate]);

  return (
    <div>
      <ArrowButton
        onClick={() =>
          handleAvailabilityClick(
            selectedDate,
            setAvailableTimeSlots,
            availabilities,
            setLoading
          )
        }
      >
        <FormattedMessage defaultMessage={`Verfügbarkeit prüfen`} id="RUcNm8" />
      </ArrowButton>
      <div className="mt-5">
        <FormattedMessage
          defaultMessage={`Wann möchten Sie die Behandlung in Anspruch nehmen?`}
          id="lVibW0"
        />
      </div>
      {error && (
        <div className="my-5">
          <ErrorMessage message={error} />
        </div>
      )}
      <ul className="my-5 flex flex-col gap-8">
        {availableTimeSlots?.length > 0 &&
          availableTimeSlots.map((timeSlot, index) => (
            <li
              key={index}
              className="grid grid-cols-1 gap-1 lg:grid-cols-[230px_1fr] lg:gap-16"
            >
              <span className="flex items-center whitespace-nowrap">
                {convertToUTCTime(new Date(timeSlot?.TimeSpan?.Start))} bis{' '}
                {convertToUTCTime(new Date(timeSlot?.TimeSpan?.End))} Uhr
              </span>
              <ArrowButton
                onClick={() => {
                  // Use selected variation and add information of selected timeslot to reserve it.
                  const selectedTimeSlotVariation = {
                    ...selectedVariation,
                    quantity: selectedVariation.quantity,
                    meta: {
                      combine: false,
                      fields: {
                        field_start_date: convertToDateAndTime(
                          new Date(timeSlot?.TimeSpan?.Start)
                        ),
                        field_end_date: convertToDateAndTime(
                          new Date(timeSlot?.TimeSpan?.End)
                        ),
                        field_booking_for: bookFor.value,
                        field_class_id:
                          timeSlot?.ClassIDs?.UniqueID?.[0]?.value,
                      },
                    },
                  };
                  handleReservationClick(
                    selectedTimeSlotVariation,
                    setShowPopUpCartItemAdded,
                    setLoading,
                    setError,
                    locale
                  );
                }}
              >
                Ritual buchen
              </ArrowButton>
            </li>
          ))}
        {availableTimeSlots?.length < 1 && (
          <strong>
            <FormattedMessage
              defaultMessage={`An diesem Tag sind leider nicht mehr genug Plätze verfügbar
            <br />
            Bitte wählen Sie einen anderen Tag oder eine andere Präferenz.`}
              id="R1fCuK"
            />
          </strong>
        )}
      </ul>
    </div>
  );
}

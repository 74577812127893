export function getDefaultVariation(product, productVariations) {
  const defaultVariation =
    productVariations?.find(
      (variation) => variation.id === product.default_variation.id
    ) || {};
  // Initialize default values.
  defaultVariation.quantity = 1;

  // Don't show a default price on flexible vouchers.
  if (defaultVariation.field_has_flexible_price) {
    defaultVariation.price.number = '';
  }

  return defaultVariation;
}

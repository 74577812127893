export default function Amount({
  onQuantityChange,
  quantity,
  item,
  label,
  min,
  max,
}) {
  return (
    <span className="my-14 font-bold">
      {label}
      {min !== max && (
        <button
          className="ml-7 lg:text-base"
          onClick={() =>
            onQuantityChange(item, Math.max(parseInt(quantity, 10) - 1, min))
          }
        >
          -
        </button>
      )}
      <span className="ml-5">{parseInt(quantity, 10)}</span>
      {min !== max && (
        <button
          className="ml-5 lg:text-base"
          onClick={() =>
            onQuantityChange(item, Math.min(parseInt(quantity, 10) + 1, max))
          }
        >
          +
        </button>
      )}
    </span>
  );
}

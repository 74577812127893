import Ticket from './Ticket';
import SpecialEventTicket from './SpecialEventTicket';

interface TicketProductProps {
  product: any;
  productVariations: any;
}

export default function TicketProduct({
  product,
  productVariations,
  ...props
}: TicketProductProps) {
  if (product?.field_special_event) {
    return (
      <SpecialEventTicket
        product={product}
        productVariations={productVariations}
      />
    );
  } else {
    return <Ticket product={product} productVariations={productVariations} />;
  }
}

import { startAndEndDate, convertDateYmd } from './date';

const url = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL;

// Returns the available dates for the current variation selection.
export async function getAvailableDatesForTickets(
  selectedVariations: Array<any>,
  dates: startAndEndDate
) {
  if (selectedVariations?.length < 1) {
    return [];
  }
  let collectedVariationIds = '';
  selectedVariations?.map((variation) => {
    if (variation?.id) {
      collectedVariationIds = collectedVariationIds
        ? `${collectedVariationIds},${variation.id}`
        : variation.id;
    }
  });

  const response = await fetch(
    `${url}/de/api/ticket/contingent?start=${dates.startDate}&end=${dates.endDate}&product_id=${collectedVariationIds}`,
    {
      method: 'GET',
    }
  );
  if (!response.ok) {
    console.log('Get available dates response status: ' + response.status);
    console.log('Get available dates response: ', await response.json());
    return false;
  } else {
    return await response.json();
  }
}

// Checks available quantity and converts available dates to Date objects.
export function convertAvailableTicketDates(
  dates,
  selectedVariations
): Array<Date> {
  const availabilities = dates?.body_content?.availabilities;
  if (!availabilities) {
    return [];
  }
  const availableDays = Object.keys(availabilities);
  const quantityVariations = selectedVariations.filter(
    (variation) => variation.quantity > 0
  );

  const availableDaysForSelection = [];

  availableDays.forEach((day) => {
    const isAvailableForSelection = !quantityVariations.some((variation) => {
      const dayToCheck = availabilities?.[day]?.[variation?.sku];
      if (dayToCheck) {
        const availableQuantity = dayToCheck.Quantity - dayToCheck.QuantityUsed;
        return variation?.quantity > availableQuantity;
      }
    });
    if (isAvailableForSelection) {
      availableDaysForSelection.push(new Date(day));
    }
  });
  return availableDaysForSelection;
}
// Add start and end date to all variations.
export function addSelectedDateToVariations(selectedDate, selectedVariations) {
  const convertDate = convertDateYmd(selectedDate);
  let data = [];
  selectedVariations?.map((variation) => {
    variation.meta = {
      fields: {
        field_start_date: convertDate,
        field_end_date: convertDate,
      },
    };
    data.push(variation);
  });

  return data;
}

// Checks if selected quantity is available on selected date.
export function hasAvailableTicketQuantity(
  availabilites,
  selectedVariations
): boolean {
  const quantityVariations = selectedVariations.filter(
    (variation) => variation.quantity > 0
  );
  const isAvailable = quantityVariations.every((variation) => {
    const dayToCheck = availabilites?.[variation?.sku];
    if (dayToCheck) {
      const availableQuantity = dayToCheck.Quantity - dayToCheck.QuantityUsed;
      return variation?.quantity <= availableQuantity;
    }
    return false;
  });
  return isAvailable;
}

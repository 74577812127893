import ArrowButton from 'components/Misc/ArrowButton';
import { convertToUTCTime } from 'lib/date';
import {
  convertBookingInfoToString,
  convertBookingStringToBookingInfo,
  getAvailablePackageTimeSlot,
  getDeletionStringForBookedIds,
  getEarliestTimeslot,
  getPackageBookingIds,
  getTreatmentDates,
} from 'lib/package-client';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { BOOK_FOR } from '../BookForStep/BookForStep';

const handleAvailabilityClick = async (
  selectedDate: Date,
  genderPreference,
  bookFor,
  selectedVariation,
  setLoading,
  setAvailability,
  setIsValidProductSelection,
  setSelectedVariations,
  setEarlierRequestDates
) => {
  setLoading(true);

  // Set date to 00:00 in UTC timezone.
  selectedDate.setUTCHours(0, 0, 0, 0);
  const timeSlotResponse = await getAvailablePackageTimeSlot(
    selectedVariation.id,
    selectedDate,
    genderPreference,
    selectedVariation.quantity,
    getDeletionStringForBookedIds(selectedVariation)
  );
  const isSuccessfulResponse =
    timeSlotResponse?.body_content?.Result.resultStatusFlag == 'SUCCESS';

  if (!isSuccessfulResponse) {
    setIsValidProductSelection(false);
    setAvailability(false);
    setLoading(false);
    return;
  }

  const bookingInfo = getTreatmentDates(
    timeSlotResponse,
    selectedVariation.sku
  );

  const bookingIdPackage = getPackageBookingIds(
    timeSlotResponse,
    selectedVariation.sku
  );

  // Adapt the variation to contain the additional fields in the cart.
  const selectedDateVariation = {
    ...selectedVariation,
    meta: {
      combine: false,
      fields: {
        field_gender_preference: genderPreference.value,
        field_reservation_start_dates: bookingInfo.map((info) =>
          convertBookingInfoToString(
            info.bookingId,
            info.startDate,
            info.title,
            info.templateId
          )
        ),
        field_reservation_end_dates: bookingInfo.map((info) =>
          convertBookingInfoToString(
            info.bookingId,
            info.endDate,
            info.title,
            info.templateId
          )
        ),
        field_booking_id: bookingIdPackage,
        field_booking_for: bookFor.value,
      },
    },
  };

  setEarlierRequestDates([]);
  setSelectedVariations([selectedDateVariation]);
  setAvailability(isSuccessfulResponse);
  setIsValidProductSelection(isSuccessfulResponse);

  setLoading(false);
};

const handleLaterClick = async (
  genderPreference,
  bookFor,
  selectedVariation,
  setLoading,
  setAvailability,
  setIsValidProductSelection,
  setSelectedVariations,
  earlierRequestDates,
  setEarlierRequestDates
) => {
  setLoading(true);

  const lastRequestDate = new Date(
    getEarliestTimeslot(
      selectedVariation.meta.fields.field_reservation_start_dates
    ).date
  );
  lastRequestDate.setMinutes(lastRequestDate.getUTCMinutes() + 15);
  const timeSlotResponse = await getAvailablePackageTimeSlot(
    selectedVariation.id,
    lastRequestDate,
    genderPreference,
    selectedVariation.quantity,
    getDeletionStringForBookedIds(selectedVariation)
  );

  const isSuccessfulResponse =
    timeSlotResponse?.body_content?.Result.resultStatusFlag == 'SUCCESS';

  setAvailability(isSuccessfulResponse);
  setIsValidProductSelection(isSuccessfulResponse);

  if (!isSuccessfulResponse) {
    setIsValidProductSelection(false);
    setAvailability(false);
    setLoading(false);
    return;
  }

  setEarlierRequestDates([...earlierRequestDates, lastRequestDate]);
  const bookingInfo = getTreatmentDates(
    timeSlotResponse,
    selectedVariation.sku
  );
  const bookingIdPackage = getPackageBookingIds(
    timeSlotResponse,
    selectedVariation.sku
  );

  // Adapt the variation to contain the additional fields in the cart.
  const selectedDateVariation = {
    ...selectedVariation,
    meta: {
      combine: false,
      fields: {
        field_gender_preference: genderPreference.value,
        field_reservation_start_dates: bookingInfo.map((info) =>
          convertBookingInfoToString(
            info.bookingId,
            info.startDate,
            info.title,
            info.templateId
          )
        ),
        field_reservation_end_dates: bookingInfo.map((info) =>
          convertBookingInfoToString(
            info.bookingId,
            info.endDate,
            info.title,
            info.templateId
          )
        ),
        field_booking_id: bookingIdPackage,
        // Get the enum key from the value.
        field_booking_for: bookFor.value,
      },
    },
  };
  setSelectedVariations([selectedDateVariation]);

  setLoading(false);
};

const handleEarlierClick = async (
  genderPreference,
  bookFor,
  selectedVariation,
  setLoading,
  setAvailability,
  setIsValidProductSelection,
  setSelectedVariations,
  earlierRequestDates,
  setEarlierRequestDates
) => {
  setLoading(true);

  const requestDate = earlierRequestDates.pop();
  requestDate.setMinutes(requestDate.getUTCMinutes() - 15);
  const timeSlotResponse = await getAvailablePackageTimeSlot(
    selectedVariation.id,
    requestDate,
    genderPreference,
    selectedVariation.quantity,
    getDeletionStringForBookedIds(selectedVariation)
  );

  const isSuccessfulResponse =
    timeSlotResponse?.body_content?.Result.resultStatusFlag == 'SUCCESS';

  setAvailability(isSuccessfulResponse);
  setIsValidProductSelection(isSuccessfulResponse);

  if (!isSuccessfulResponse) {
    setIsValidProductSelection(false);
    setAvailability(false);
    setLoading(false);
    return;
  }

  setEarlierRequestDates(earlierRequestDates);
  const bookingInfo = getTreatmentDates(
    timeSlotResponse,
    selectedVariation.sku
  );

  const bookingIdPackage = getPackageBookingIds(
    timeSlotResponse,
    selectedVariation.sku
  );

  // Adapt the variation to contain the additional fields in the cart.
  const selectedDateVariation = {
    ...selectedVariation,
    meta: {
      combine: false,
      fields: {
        field_gender_preference: genderPreference.value,
        field_reservation_start_dates: bookingInfo.map((info) =>
          convertBookingInfoToString(
            info.bookingId,
            info.startDate,
            info.title,
            info.templateId
          )
        ),
        field_reservation_end_dates: bookingInfo.map((info) =>
          convertBookingInfoToString(
            info.bookingId,
            info.endDate,
            info.title,
            info.templateId
          )
        ),
        field_booking_id: bookingIdPackage,
        field_booking_for: bookFor.value,
      },
    },
  };
  setSelectedVariations([selectedDateVariation]);

  setLoading(false);
};

export default function CheckAvailabilityStep({
  selectedDate,
  genderPreference,
  bookFor,
  selectedVariation,
  setLoading,
  setIsValidProductSelection,
  setSelectedVariations,
  isValidProductSelection,
}) {
  const [availability, setAvailability] = useState(null);
  useEffect(() => {
    setIsValidProductSelection(false);
    setAvailability(null);
  }, [genderPreference, selectedDate, setIsValidProductSelection]);
  const [earlierRequestDates, setEarlierRequestDates] = useState(selectedDate);

  return (
    <div className="mt-10">
      <button
        type="button"
        className="link-arrow mb-5 align-middle text-xl uppercase leading-10 lg:whitespace-nowrap"
        onClick={() =>
          handleAvailabilityClick(
            selectedDate,
            genderPreference,
            bookFor,
            selectedVariation,
            setLoading,
            setAvailability,
            setIsValidProductSelection,
            setSelectedVariations,
            setEarlierRequestDates
          )
        }
      >
        <FormattedMessage defaultMessage={`Verfügbarkeit prüfen`} id="RUcNm8" />
      </button>

      {availability && isValidProductSelection && (
        <strong>
          <FormattedMessage
            defaultMessage={`Der ausgewählte Termin steht für Sie zur Verfügung. Sie können das
          Produkt zum Warenkorb hinzufügen.`}
            id="2TSlhJ"
          />
          <div className="py-5">
            {selectedVariation.meta.fields.field_reservation_start_dates.map(
              (info, index) => {
                const bookingInfo = convertBookingStringToBookingInfo(info);
                return (
                  <span
                    key={index}
                    className="my-5 flex items-center lg:whitespace-nowrap"
                  >
                    {bookingInfo.title + ': '}
                    <br className="lg:hidden" />
                    <FormattedMessage
                      defaultMessage={`{startTime} - {endTime} Uhr`}
                      id="AOte8p"
                      values={{
                        startTime: convertToUTCTime(new Date(bookingInfo.date)),
                        endTime: convertToUTCTime(
                          new Date(
                            convertBookingStringToBookingInfo(
                              selectedVariation.meta.fields
                                .field_reservation_end_dates[index]
                            ).date
                          )
                        ),
                      }}
                    />
                  </span>
                );
              }
            )}
            <div className="flex flex-col gap-y-4 lg:gap-y-6">
              {earlierRequestDates?.length > 0 && (
                <ArrowButton
                  pointingLeft={true}
                  onClick={() =>
                    handleEarlierClick(
                      genderPreference,
                      bookFor,
                      selectedVariation,
                      setLoading,
                      setAvailability,
                      setIsValidProductSelection,
                      setSelectedVariations,
                      earlierRequestDates,
                      setEarlierRequestDates
                    )
                  }
                >
                  <FormattedMessage defaultMessage={`Früher`} id="9MJUnO" />
                </ArrowButton>
              )}
              <ArrowButton
                onClick={() =>
                  handleLaterClick(
                    genderPreference,
                    bookFor,
                    selectedVariation,
                    setLoading,
                    setAvailability,
                    setIsValidProductSelection,
                    setSelectedVariations,
                    earlierRequestDates,
                    setEarlierRequestDates
                  )
                }
              >
                <FormattedMessage defaultMessage={`Später`} id="z4ofjq" />
              </ArrowButton>
            </div>
          </div>
        </strong>
      )}
      {(availability === null || !isValidProductSelection) && (
        <strong>
          <FormattedMessage
            defaultMessage={`Bitte überprüfen Sie die Verfügbarkeit bevor Sie das Produkt zum
          Warenkorb hinzufügen.`}
            id="3hdVDz"
          />
        </strong>
      )}
      {availability === false && (
        <strong>
          <FormattedMessage
            defaultMessage={`Basierend auf den ausgewählten Präferenzen konnte leider keine
          verfügbare Person gefunden werden. 
          {breakingLine}
          {breakingLine}
          Bitte wählen Sie einen anderen Tag oder eine andere Präferenz und
          prüfen Sie die Verfügbarkeit Ihrer Auswahl.`}
            id="fZKL0c"
            values={{
              breakingLine: <br />,
            }}
          />
        </strong>
      )}
    </div>
  );
}

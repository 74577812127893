export default function ProductDetails({ product }) {
  return (
    <>
      <h2 className="font-serif">{product?.title}</h2>
      <h3 className="uppercase text-h3-m lg:text-h3-d">
        {product?.field_byline}
      </h3>
      {product?.field_description?.value && (
        <div
          className="text-base-m lg:text-base-d prose"
          dangerouslySetInnerHTML={{
            __html: product.field_description.value,
          }}
        />
      )}
    </>
  );
}

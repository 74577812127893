import Steps from 'components/Commerce/Steps';
import TicketQuantityStep from 'components/Commerce/Steps/TicketQuantityStep';
import AddToCartStep from 'components/Commerce/Steps/AddToCartStep';
import { useEffect, useState } from 'react';
import PopUpCartItemAdded from 'components/Commerce/PopUpCartItemAdded';
import LoadingSpinner from 'components/Misc/LoadingSpinner';
import ProductDetails from 'components/Commerce/Steps/ProductDetails';
import HeaderSubPage from 'components/HeaderSubPage';
import CalendarStep from 'components/Commerce/Steps/CalendarStep';
import {
  addSelectedDateToVariations,
  convertAvailableTicketDates,
  getAvailableDatesForTickets,
} from 'lib/ticket-client';
import { convertDateYmd } from 'lib/date';
import LoadingSpinnerWrapper from 'components/Misc/LoadingSpinnerWrapper';

interface TicketProps {
  product: any;
  productVariations: any;
}

function initializeQuantity(productVariations) {
  const initializedVariations = productVariations?.map((variation) => ({
    ...variation,
    quantity:
      variation.title == 'Erwachsene' || variation.title == 'Tamina Relax 36.5°'
        ? 1
        : 0,
  }));
  return initializedVariations;
}

// Sum up prices of all variations where quantity > 0.
function calculatePrice(variations) {
  if (variations?.length > 0) {
    const price = variations.reduce((accumulatedPrice, currentVariation) => {
      if (currentVariation?.quantity > 0) {
        return (
          accumulatedPrice +
          parseFloat(currentVariation.price.number) * currentVariation.quantity
        );
      }
      return accumulatedPrice;
    }, 0);
    return price;
  }
  return 0;
}

export default function Ticket({
  product,
  productVariations,
  ...props
}: TicketProps) {
  const [selectedVariations, setSelectedVariations] = useState(
    initializeQuantity(productVariations)
  );
  const [selectedDateVariations, setSelectedDateVariations] = useState(null);
  const [showPopUpCartItemAdded, setShowPopUpCartItemAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidProductSelection, setIsValidProductSelection] = useState(false);
  const [availabilities, setAvailabilities] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  useEffect(() => {
    if (availabilities && selectedDate) {
      const availableSkus = Object.keys(
        availabilities?.[convertDateYmd(selectedDate)] ?? {}
      );
      // Set variations that are not available for the selected date to quantity of 0.
      const availableVariations = selectedVariations.map((variation) => {
        if (!availableSkus.includes(variation.sku)) {
          return { ...variation, quantity: 0 };
        }
        return variation;
      });

      setSelectedDateVariations(
        addSelectedDateToVariations(selectedDate, availableVariations)
      );
      setIsValidProductSelection(true);
    }
  }, [selectedDate]);

  // We need the availabilities to determine which variation should be selected after choosing a date.
  const getAvailabilitiesForTickets = async (
    selectedVariations,
    startAndEndDate
  ) => {
    setIsValidProductSelection(false);
    const quantityVariations = selectedVariations.filter(
      (variation) => variation.quantity > 0
    );
    const dateResponse = await getAvailableDatesForTickets(
      quantityVariations,
      startAndEndDate
    );
    if (dateResponse?.body_content?.availabilities) {
      setAvailabilities(dateResponse.body_content.availabilities);
    }
    return dateResponse;
  };

  return (
    <article {...props} className="bg-tertiary">
      <PopUpCartItemAdded
        show={showPopUpCartItemAdded}
        setShow={setShowPopUpCartItemAdded}
      />
      {loading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner size={10} />
        </LoadingSpinnerWrapper>
      )}
      <div data-product-type="TicketProduct" className="relative">
        <div className="mx-auto w-full">
          <div className="relative sm:overflow-hidden">
            <HeaderSubPage
              title={product?.title}
              imageUrl={product?.field_image?.field_media_image?.uri?.url}
              videoUrl={null}
            />
            <Steps>
              <div>
                <ProductDetails product={product} />
                <TicketQuantityStep
                  selectedVariations={selectedVariations}
                  setSelectedVariations={setSelectedVariations}
                  setSelectedDate={setSelectedDate}
                />
                <CalendarStep
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedVariations={selectedVariations}
                  getAvailableDates={getAvailabilitiesForTickets}
                  convertAvailableDates={convertAvailableTicketDates}
                  minDate={tomorrow}
                  preloadYear={product?.field_preload_year}
                />
                {selectedDate && (
                  <div className="py-5">
                    Preis{' '}
                    {Intl.NumberFormat('de-CH', {
                      style: 'currency',
                      currency: 'CHF',
                    }).format(calculatePrice(selectedDateVariations))}
                  </div>
                )}
              </div>
              <AddToCartStep
                selectedVariations={selectedDateVariations}
                setShowPopUpCartItemAdded={setShowPopUpCartItemAdded}
                isValidProductSelection={isValidProductSelection}
                setLoading={setLoading}
              />
            </Steps>
          </div>
        </div>
      </div>
    </article>
  );
}

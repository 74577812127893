export default function StepHeadline({ headline, subheadline }) {
  return (
    <div className="mb-4">
      <h3 className="text-h3-m uppercase lg:text-h3-d">{headline}</h3>
      {subheadline && (
        <div className="prose text-base-m lg:text-base-d">{subheadline}</div>
      )}
    </div>
  );
}

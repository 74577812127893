import Radio from 'components/forms/Radio';
import RadioGroup from 'components/forms/RadioGroup';
import { FormattedMessage } from 'react-intl';

export const BOOK_FOR = {
  myself: {
    value: 'myself',
    label: <FormattedMessage defaultMessage={`Mich`} id="jmT8pq" />,
  },
  someone: {
    value: 'someone',
    label: <FormattedMessage defaultMessage={`Jemand anderen`} id="4IIsYR" />,
  },
} as const;

type ObjectValues<T> = T[keyof T];
export type BookFor = ObjectValues<typeof BOOK_FOR>;

export default function BookForStep({ bookFor, setBookFor }) {
  return (
    <div className="mb-12 flex flex-col gap-4 md:gap-x-8 lg:flex-row">
      <span className="whitespace-nowrap leading-8">
        <FormattedMessage defaultMessage={`Ich buche für`} id="Rm1X78" />
      </span>
      <RadioGroup>
        <Radio
          id={`bookfor-me`}
          name="bookforselection"
          onClick={() => setBookFor(BOOK_FOR.myself)}
          checked={bookFor.value == BOOK_FOR.myself.value}
          onChange={() => {}}
          label={BOOK_FOR.myself.label}
        />
        <Radio
          id={`bookfor-someoneelse`}
          name="bookforselection"
          onClick={() => setBookFor(BOOK_FOR.someone)}
          checked={bookFor.value == BOOK_FOR.someone.value}
          onChange={() => {}}
          label={BOOK_FOR.someone.label}
        />
      </RadioGroup>
    </div>
  );
}

export default function AnnotationInput({ id, label, onChange }) {
  return (
    <div className="mt-2 grid grid-cols-6 gap-x-2 gap-y-2 lg:gap-x-4">
      <label htmlFor={id} className="col-span-6 pt-1 lg:col-span-2">
        {label}
      </label>
      <input
        className={`col-span-6 block w-full border-0 border-b-2 border-secondary bg-transparent focus:border-primary focus:ring-0 focus-visible:outline-none lg:col-span-4`}
        type="textarea"
        onChange={onChange}
      />
    </div>
  );
}
